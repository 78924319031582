import React from 'react';
import loadable from '@loadable/component';
import ContentData from 'content/services-servicesList.json';
import { colors } from 'utils/variables';
import Container from 'components/_shared/Container/Container';
import ServiceTile from './components/ServiceTile/ServiceTile';

import { StyledWrapper, StyledShape1, StyledTitle, StyledTileContainer } from './ServicesList.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const getService = (service, index) => <ServiceTile service={service} key={`${service.title}-${index}`} />;

const Identity = () => (
  <StyledWrapper>
    <StyledShape1>
      <AnimatedShape
        name="FormContainer-1"
        originalWidth={1543}
        originalHeight={918}
        fill={colors.peachMilk}
        paths={[
          ' M 58.923 43.965 C 69.078 35.627 81.022 21.81 76.026 12.099 C 71.031 2.388 55.782 -2.842 46.123 1.598 C 36.464 6.038 21.956 -2.833 9.787 3.68 C -2.381 10.192 -2.442 24.603 5.717 27.901 C 13.877 31.198 48.768 52.303 58.923 43.965 Z ',
          ' M 18.327 1.935 C 8.172 10.273 -3.772 24.09 1.224 33.801 C 6.219 43.512 21.468 48.742 31.127 44.302 C 40.786 39.862 55.294 48.733 67.463 42.22 C 79.631 35.708 79.692 21.297 71.533 17.999 C 63.373 14.702 28.482 -6.403 18.327 1.935 Z ',
        ]}
      />
    </StyledShape1>
    <StyledTitle dangerouslySetInnerHTML={{ __html: ContentData.title }} />
    <Container>
      <StyledTileContainer>{ContentData.services.map(getService)}</StyledTileContainer>
    </Container>
  </StyledWrapper>
);

export default Identity;
