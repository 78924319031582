import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledIcon, StyledTitle, StyledDescription } from './ServiceTile.styled';

const ServiceTile = ({ service }) => (
  <StyledWrapper>
    <StyledIcon src={service.iconSrc} alt={service.title} />
    <StyledTitle dangerouslySetInnerHTML={{ __html: service.title }} />
    <StyledDescription dangerouslySetInnerHTML={{ __html: service.description }} />
  </StyledWrapper>
);

ServiceTile.propTypes = {
  service: PropTypes.shape({
    iconSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default ServiceTile;
