import React from 'react';
import Layout from 'templates/Layout/Layout';
import ContentData from 'content/services-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import { Identity, ServicesList } from 'components/Services';
import PageHeading from 'components/_shared/PageHeading/PageHeading';

const Services = () => (
  <Layout metaTitle="Nasze usługi" metaDescription="Sprawdź w czym mozemy Ci pomóc.">
    <PageHeading text="Usługi" />
    <Identity />
    <ServicesList />
    <InformationOffice contentData={ContentData} />
  </Layout>
);

export default Services;
