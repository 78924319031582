import styled from 'styled-components';
import { fontWeight, colors } from 'utils/variables';
import { Link } from 'gatsby';
import Image from 'components/_shared/Image/Image';
import hexToRGBA from 'utils/hexToRGBA';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 3.5rem 3.7rem 2.3rem 3.7rem;
  background-color: ${colors.white};
  box-shadow: 0 0 0.3rem ${hexToRGBA(colors.black, 0.1)};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0.4rem 0.4rem 0 0 ${colors.butter};
    left: 0;
    top: 0;
    pointer-events: none;
  }
`;

export const StyledIcon = styled(Image)`
  width: 6rem;
  height: 6rem;
`;

export const StyledTitle = styled.h3`
  padding: 0;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin: 1.5rem 0 2.3rem 0;
`;

export const StyledDescription = styled.span`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
`;

export const StyledLink = styled(Link)`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin-top: 3rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
