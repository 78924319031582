import React from 'react';
import ContentData from 'content/services-identity.json';
import { StyledWrapper, StyledButton, StyledContainer, StyledImage, StyledContent, StyledTitle, StyledDescription } from './Identity.styled';

const Identity = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledContent>
        <StyledTitle dangerouslySetInnerHTML={{ __html: ContentData.title }} />
        <StyledDescription dangerouslySetInnerHTML={{ __html: ContentData.description }} />
        <StyledButton
          title="Zobacz nasze opinie"
          href="https://www.google.com/search?ei=4hkhX5iZKejhrgTrr7ZQ&q=Biuro+Rachunkowe+Hossa+w+Rybniku&oq=Biuro+Rachunkowe+Hossa+w+Rybniku&gs_lcp=CgZwc3ktYWIQAzIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yAggmUJTEAViUxAFgscgBaABwAHgAgAFMiAFMkgEBMZgBAKABAqABAaoBB2d3cy13aXrAAQE&sclient=psy-ab&ved=0ahUKEwiYkuyp7fHqAhXosIsKHeuXDQoQ4dUDCAw&uact=5#lrd=0x47114f35e2f0c66f:0x4c1c8ae65ea0be,1,,,"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zobacz nasze opinie
        </StyledButton>
      </StyledContent>
      <StyledImage src="services-information-1.jpg" />
    </StyledContainer>
  </StyledWrapper>
);

export default Identity;
