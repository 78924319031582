import styled from 'styled-components';
import { breakPoints, fontWeight, colors } from 'utils/variables';

export const StyledWrapper = styled.div`
  position: relative;
  padding: 6rem 0 12rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 0 0 12rem 0;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 0 0 2rem 0;
  }
`;

export const StyledShape1 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    left: 0;
    top: -28rem;
    height: 172rem;
    width: 156rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    left: 22rem;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  width: 100%;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  display: block;
  text-align: center;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 3.2rem;
    line-height: 4.8rem;
    margin: 0 0 2.8rem 0;
  }
`;

export const StyledTileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  column-gap: 20px;

  @media (min-width: ${breakPoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakPoints.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
